<template>
    <GenericPage
        :header-props="{
            title: $t('cp__settings__integration__service_api__title'),
            subtitle: $t('cp__settings__integration_service_api__subtitle'),
            disableBackButton: true,
        }"
    >
        <SettingsForm class="settings_form">
            <SettingsBlock
                class="settings_block"
                :title="$t('cp__settings__integration_service__api_key__title')"
            >
                <SettingsSection label="">
                    <div class="api_name" v-for="value in generatedApiNames" :key="value.id">
                        <TextInput :label="value.name" disabled />
                        <InlineButton @click.native="confirmDeleteActiveButton(value.id)" grey>
                            <font-awesome-icon slot="icon" :icon="['far', 'times']" size="lg" />
                        </InlineButton>
                    </div>
                    <Button
                        v-if="generatedApiNames.length === 0"
                        buttonStyle="secondary"
                        @click.native="requestPopupActive = true"
                        >{{ $t("cp__settings__research_api__api_key__request_button") }}</Button
                    >
                    <button
                        v-else
                        type="button"
                        class="add_more"
                        @click="requestPopupActive = true"
                    >
                        <span>{{ $t("cp__add_another_label") }}</span>
                        <font-awesome-icon :icon="['far', 'plus']" />
                    </button>
                </SettingsSection>
            </SettingsBlock>
        </SettingsForm>
        <!-- class="popup_content" is seperate added multiple times. This is because of style bug with confirmPopup component.-->
        <Popup
            v-if="requestPopupActive || copyApiPopupActive"
            :closeAllowed="false"
            :isLoading="loading"
        >
            <div>
                <h2 class="headline6 settings_block__title popup_content">
                    {{
                        requestPopupActive
                            ? $t("cp__settings__integration__service__generate__api__name")
                            : $t("cp__settings__integration__service_new_api__generated")
                    }}
                </h2>
                <h3
                    v-if="requestPopupActive"
                    class="body2 popup_content"
                    v-html="$t('cp__settings__integration__service__generate_api__message')"
                ></h3>
                <h3
                    v-if="copyApiPopupActive"
                    class="body2 popup_content"
                    v-html="$t('cp__settings__integration__service_new_api__generated_message')"
                ></h3>
                <div class="name_input_wrapper popup_content">
                    <TextInput
                        v-if="requestPopupActive"
                        v-model="apiName"
                        :label="$t('cp__settings__general__communications__name_title')"
                        enableExternalValidation
                        :externalValidationError="apiNameValidation"
                        :externalValidationErrorMessage="
                            $t('cp__settings__integration_service__validation__msg')
                        "
                    />

                    <FieldToClipboard
                        v-if="copyApiPopupActive"
                        :value="apiKey"
                        @onCopied="triggerCopyToast"
                    />
                </div>
                <div class="popup_footer popup_content">
                    <Button
                        v-if="requestPopupActive"
                        :disabled="loading"
                        buttonStyle="secondary"
                        @click.native="cancelApiRequest"
                    >
                        {{ $t("cp__generic__cancel") }}
                    </Button>
                    <Button
                        v-if="copyApiPopupActive"
                        :disabled="loading"
                        buttonStyle="secondary"
                        @click.native="warningPopupActive = true"
                    >
                        {{ $t("cp__general__close") }}
                    </Button>
                    <Button
                        v-if="requestPopupActive"
                        buttonStyle="primary"
                        :loading="loading"
                        :disabled="!apiName || apiNameValidation"
                        @click.native="requestNewApiKey"
                    >
                        {{ $t("cp__settings__integration_service_api__api_key__request_button") }}
                    </Button>
                </div>
            </div>
        </Popup>
        <ConfirmPopup
            v-if="warningPopupActive"
            :title="$t('cp__settings__integration_service_pop_up_warning')"
            :message="$t('cp__settings__integration_service_pop_up_warning_message')"
            :confirmBtn="$t('cp__settings__integration_service_pop_up_warning_confirm')"
            :cancel-btn="$t('cp__generic__cancel')"
            @confirm="closeAllPopups"
            :confirmBtnIsLoading="loading"
            @cancel="warningPopupActive = false"
            closeAllowed
        />
        <ConfirmPopup
            v-if="confirmDeleteActive"
            :title="$t('cp__settings__integration_service__api_key__deleted__popup_title')"
            :message="$t('cp__settings__integration_service__api_key__deleted__popup_msg')"
            :confirmBtn="$t('cp__settings__integration_service_pop_up_delete_warning_confirm')"
            :cancel-btn="$t('cp__generic__cancel')"
            @confirm="deleteApiKey"
            :confirmBtnIsLoading="loading"
            @cancel="confirmDeleteActive = false"
            closeAllowed
        />
    </GenericPage>
</template>

<script>
import InlineButton from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/InlineButton.vue";
import ConfirmPopup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/ConfirmPopup.vue";
import Popup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/Popup.vue";
import FieldToClipboard from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/FieldToClipboard.vue";
import TextInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import * as Sentry from "@sentry/vue";
import GenericPage from "@/components/Global/Molecules/GenericPage.vue";
import SettingsForm from "@/components/Settings/Molecules/SettingsForm.vue";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { resolveGraphQLErrors } from "@/helpers/resolveGraphQLErrors";
import deleteIntegrationApi from "../../graphql/settings/deleteIntegrationApi.gql";
import generateIntergrationApi from "../../graphql/settings/generateIntergrationApi.gql";
import getIntergrationApi from "../../graphql/settings/getIntergrationApi.gql";

library.add(faPlus, faTimes);

export default {
    name: "ResearchApiSettings",
    components: {
        Button,
        FieldToClipboard,
        GenericPage,
        SettingsForm,
        SettingsBlock,
        SettingsSection,
        Popup,
        TextInput,
        ConfirmPopup,
        InlineButton,
        FontAwesomeIcon,
    },
    data() {
        return {
            requestPopupActive: false,
            copyApiPopupActive: false,
            warningPopupActive: false,
            confirmDeleteActive: false,
            apiNameValidation: false,
            loading: false,
            apiKey: "",
            apiName: "",
            deleteId: "",
            generatedApiNames: [],
        };
    },
    methods: {
        closeAllPopups() {
            this.requestPopupActive = false;
            this.copyApiPopupActive = false;
            this.warningPopupActive = false;
            this.apiName = "";
            this.apiKey = "";
            this.deleteId = "";
            this.getGeneratedApiNames();
        },
        cancelApiRequest() {
            this.requestPopupActive = false;
            this.apiNameValidation = false;
            this.apiName = "";
        },
        async triggerCopyToast(value) {
            await this.$store.dispatch("pushNotification", {
                type: "success",
                title: this.$t("cp__general__copied"),
                message: this.$t("cp__general__copied__message", { value }),
            });
        },
        async requestNewApiKey() {
            this.loading = true;
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: generateIntergrationApi,
                    variables: {
                        input: {
                            name: this.apiName,
                        },
                    },
                });
                this.apiKey = data.generateApiKey.value;
                this.requestPopupActive = false;
                this.copyApiPopupActive = true;

                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t("cp__settings__integration_service__api_key__popup_title"),
                    message: this.$t("cp__settings__integration_service__api_key__success_msg"),
                });
            } catch (e) {
                if (resolveGraphQLErrors(e).includes("DUPLICATE")) {
                    this.apiNameValidation = true;
                } else {
                    await this.$store.dispatch("pushNotification", {
                        type: "error",
                        title: this.$t("cp__settings__integration_service__api_key__popup_title"),
                        message: this.$t("cp__settings__integration_service__api_key__error_msg"),
                    });
                }
            }
            this.loading = false;
        },
        confirmDeleteActiveButton(id) {
            this.deleteId = id;
            this.confirmDeleteActive = true;
        },
        async deleteApiKey() {
            this.confirmDeleteActive = false;
            try {
                await this.$apollo.mutate({
                    mutation: deleteIntegrationApi,
                    variables: {
                        id: this.deleteId,
                    },
                });
                await this.$store.dispatch("pushNotification", {
                    type: "success",
                    title: this.$t(
                        "cp__settings__integration_service__api_key__deleted__toaster_title"
                    ),
                    message: this.$t(
                        "cp__settings__integration_service__api_key__deleted__success_msg"
                    ),
                });
                this.getGeneratedApiNames();
            } catch (e) {
                Sentry.captureException(e);
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t(
                        "cp__settings__integration_service__api_key__deleted__popup_title"
                    ),
                    message: this.$t(
                        "cp__settings__integration_service__api_key__delete__error_msg"
                    ),
                });
            }
            this.deleteId = "";
        },
        async getGeneratedApiNames() {
            const { data } = await this.$apollo.query({
                query: getIntergrationApi,
                fetchPolicy: "network-only",
            });
            this.generatedApiNames = data.getApiKeys;
        },
    },
    watch: {
        apiName() {
            if (this.apiNameValidation) {
                this.apiNameValidation = false;
            }
        },
    },

    async mounted() {
        await this.getGeneratedApiNames();
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "@/style_variables/style_variables.scss";

.settings_form {
    width: 100%;
    max-width: 950px;
    margin-bottom: 40px;
}

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:last-of-type {
        margin-bottom: 40px;
    }
}

.name_input_wrapper {
    margin-top: 20px;
}

.popup_content {
    @include media($isDesktop...) {
        width: 400px;
        max-width: 400px;
    }
}

.popup_footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 30px;
}

.api_name {
    display: flex;
    margin-bottom: 20px;
}

.add_more {
    @extend %button_typography_style;
    border: none;
    background-color: $white;
    cursor: pointer;
    padding: 0;
    color: $blue;

    &:hover {
        color: $blue_sapphire;
    }

    span {
        padding-right: 8px;
    }
}
</style>
